.social-media-container {
  position: relative;
  display: flex;
  justify-content: center;
  .social-icon-link {
    color: #ffffff;
    width: 24px;
    height: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    // border: 1px solid #ffffff;
    border-radius: 50%;
    -webkit-transition: 165ms;
    -o-transition: 165ms;
    transition: 165ms;
    margin: 0 12px;
    svg {
      transition: 100ms ease;
      height: 17px;
    }
    &:hover {
      // background-color: #ffffff;
      // color: #333333;
      svg {
        transform: translateY(-3px);
      }
    }
  }
}
