// load fonts the fun way
@import url('https://fonts.googleapis.com/css2?family=East+Sea+Dokdo&family=Jost:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');

:root {
  --gold: #bd9e3c;
  --goldSecondary: #d4bf39;
  --goldAccent: #a38934;
  --purpleAccent: #9986b7;
  --purplePrimary: #593a8a;
  --purpleSecondary: #7a61a1;
  --blackPrimary: #222222;
  --blackSeconday: #191919;
  --blackAccent: #2c333a;
  --blackAccentSecondary: #4a4f55;
  --blackBorder: #272a2e;
  --pageMaxWidth: 1200px;
  --boxShadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.085);
  --boxShadow2: 0 2px 6px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 0 0 #dadce0;
  --boxShadow3: rgba(0, 0, 0, 0.1) 0px 2px 3px 0px;
  --sideNavWidth: 85px;
  --topHeaderHeight: 50px;
  --subHeaderHeight: 40px;
  --headerHeight: 50px;
  // --headerHeight: calc(var(--topHeaderHeight) + var(--subHeaderHeight));
  --jost: 'Inter', sans-serif;
  --rubik: 'Rubik', sans-serif;
  --inter: 'Inter', sans-serif;
}

/* Scrollbar width */
::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
  // background-color: rgb(193, 193, 193);
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: #686868;
  width: 8px;
  border-radius: 8px;
}

/* Scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
}
