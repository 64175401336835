@import "https://fonts.googleapis.com/css2?family=East+Sea+Dokdo&family=Jost:wght@300;400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap";
:root {
  --gold: #bd9e3c;
  --goldSecondary: #d4bf39;
  --goldAccent: #a38934;
  --purpleAccent: #9986b7;
  --purplePrimary: #593a8a;
  --purpleSecondary: #7a61a1;
  --blackPrimary: #222;
  --blackSeconday: #191919;
  --blackAccent: #2c333a;
  --blackAccentSecondary: #4a4f55;
  --blackBorder: #272a2e;
  --pageMaxWidth: 1200px;
  --boxShadow: 0px 0px 3px 0px #00000016;
  --boxShadow2: 0 2px 6px 0 #0000001f, inset 0 -1px 0 0 #dadce0;
  --boxShadow3: #0000001a 0px 2px 3px 0px;
  --sideNavWidth: 85px;
  --topHeaderHeight: 50px;
  --subHeaderHeight: 40px;
  --headerHeight: 50px;
  --jost: "Inter", sans-serif;
  --rubik: "Rubik", sans-serif;
  --inter: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  width: 8px;
  background: #686868;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
}

.social-media-container {
  justify-content: center;
  display: flex;
  position: relative;
}

.social-media-container .social-icon-link {
  color: #fff;
  width: 24px;
  height: 24px;
  -o-transition: 165ms;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 0 12px;
  transition: all .165s;
  display: flex;
}

.social-media-container .social-icon-link svg {
  height: 17px;
  transition: all .1s;
}

.social-media-container .social-icon-link:hover svg {
  transform: translateY(-3px);
}

.CircularProgressbar {
  width: 100%;
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset .5s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  dominant-baseline: middle;
  text-anchor: middle;
  font-size: 20px;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: #0000;
}

.BA7kFG_player-level-container {
  position: relative;
}

.BA7kFG_player-level-container .BA7kFG_level__progress {
  opacity: .8;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: -100%;
  transform: translateY(-50%);
}

.BA7kFG_CircularProgressbar .BA7kFG_CircularProgressbar-text {
  dominant-baseline: central;
  text-anchor: middle;
  font-size: 20px;
}

#leaderboards-podium {
  max-width: 350px;
  height: 230px;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-end;
  margin: 80px auto 30px;
  padding: 0 10px;
  display: flex;
  position: relative;
}

#leaderboards-podium .podium-column {
  color: #222;
  background: #f0f0f0;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

#leaderboards-podium .podium-column .podium-name {
  color: #fff;
  text-align: center;
  text-overflow: ellipsis;
  width: 80%;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -25px;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
}

#leaderboards-podium .podium-column .place-number {
  text-shadow: 0 0 5px #0003;
  color: #fff;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  display: flex;
}

#leaderboards-podium .podium-column.first {
  height: 100%;
  z-index: 11;
  background-color: #fff;
  overflow: visible;
  box-shadow: 0 0 5px 4px #0000000a;
}

#leaderboards-podium .podium-column.first .podium-name {
  width: 100%;
}

#leaderboards-podium .podium-column.first .place-number {
  background: #d7ad41;
  box-shadow: 0 1px 0 1px #c79c2d;
}

#leaderboards-podium .podium-column.first:after {
  width: calc(100% + 20px);
  border-radius: 8px;
  top: -2px;
  left: -10px;
}

#leaderboards-podium .podium-column.second {
  height: 80%;
  border-bottom-left-radius: 6px;
}

#leaderboards-podium .podium-column.second .place-number {
  background: #c9c8c6;
  box-shadow: 0 1px 0 1px #b1b0b0;
}

#leaderboards-podium .podium-column.second:after {
  width: calc(100% + 8px);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  right: -1px;
}

#leaderboards-podium .podium-column.third {
  height: 65%;
  border-bottom-right-radius: 6px;
}

#leaderboards-podium .podium-column.third .place-number {
  background: #c66031;
  box-shadow: 0 1px 0 1px #a74d24;
}

#leaderboards-podium .podium-column.third:after {
  width: calc(100% + 8px);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  left: -1px;
}

#leaderboards-podium .podium-column:after {
  content: "";
  height: 8px;
  background-color: #cbcbcb;
  position: absolute;
  top: -2px;
}

#leaderboards-podium .podium-column .wins-number {
  margin-top: 10px;
  font-weight: bold;
}

.trophy-icon {
  position: absolute;
  top: -75px;
}

.trophy-icon svg {
  color: #d7ad41;
  height: 39px;
  display: block;
}

@media only screen and (max-width: 600px) {
  #leaderboards-screen .leaderboard-screen-content-wrapper {
    flex-flow: wrap-reverse;
    margin: 80px 10px 30px;
  }
}

/*# sourceMappingURL=index.d6e94578.css.map */
