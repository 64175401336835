#leaderboards-podium {
  position: relative;
  display: flex;
  flex-grow: 1;
  max-width: 350px;
  height: 230px;
  justify-content: center;
  margin: 80px auto 30px;
  align-items: flex-end;
  padding: 0 10px;

  .podium-column {
    position: relative;
    display: flex;
    align-items: center;
    flex-basis: 100%;
    flex-direction: column;
    color: #222;
    justify-content: center;
    background: #f0f0f0;

    .podium-name {
      color: #fff;
      font-size: 12px;
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      text-overflow: ellipsis;
      width: 80%;
      overflow: hidden;
      font-weight: 600;
      white-space: nowrap;
    }

    .place-number {
      font-size: 24px;
      font-weight: bold;
      text-shadow: 0 0 5px #0003;
      color: #fff;
      width: 54px;
      height: 54px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.first {
      height: 100%;
      overflow: visible;
      background-color: #fff;
      box-shadow: 0 0 5px 4px #0000000a;
      z-index: 11;

      .podium-name {
        width: 100%;
      }

      .place-number {
        background: #d7ad41;
        box-shadow: 0 1px 0 1px #c79c2d;
      }

      &::after {
        width: calc(100% + 20px);
        top: -2px;
        left: -10px;
        border-radius: 8px;
      }
    }

    &.second {
      height: 80%;
      border-bottom-left-radius: 6px;

      .place-number {
        background: #c9c8c6;
        box-shadow: 0 1px 0 1px #b1b0b0;
      }

      &::after {
        width: calc(100% + 8px);
        right: -1px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }

    &.third {
      height: 65%;
      border-bottom-right-radius: 6px;

      .place-number {
        background: #c66031;
        box-shadow: 0 1px 0 1px #a74d24;
      }

      &::after {
        width: calc(100% + 8px);
        left: -1px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    &::after {
      content: '';
      height: 8px;
      top: -2px;
      background-color: #cbcbcb;
      position: absolute;
    }

    .wins-number {
      margin-top: 10px;
      font-weight: bold;
    }
  }
}

.trophy-icon {
  position: absolute;
  top: -75px;

  svg {
    display: block;
    color: #d7ad41;
    height: 39px;
  }
}

@media only screen and (max-width: 600px) {
  #leaderboards-screen .leaderboard-screen-content-wrapper {
    flex-flow: wrap-reverse;
    margin: 80px 10px 30px;
  }
}
